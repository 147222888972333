
import { defineComponent } from "vue";
import moment from "moment";

export default defineComponent({
  data() {
    return {
      moment,
      data: [],
    };
  },
  methods: {},
});
