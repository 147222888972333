
import { defineComponent } from "vue";
import moment from "moment";

export default defineComponent({
  data() {
    return {
      moment,
      data: [
        {
          stt: "A",
          ten: "B",
          ma_vat_tu: "C",
          don_vi_tinh: "E",
          sl: "1",
          dat: "2",
          khong_dat: "3",
          phuong_thuc: "F",
        },
      ],
    };
  },
});
