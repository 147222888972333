import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.data,
    style: {"width":"100%"},
    "empty-text": "Chưa có toa xe",
    class: "table-fix"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        label: "STT",
        width: "55",
        align: "center"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.stt), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Tên, nhãn hiệu, quy cách, phẩm chất vật tư, dụng cụ toa xe, hàng hóa",
        align: "center"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.ten), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Mã số",
        width: "100",
        align: "center"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.ma_vat_tu), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "ĐVT",
        width: "100",
        align: "center"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.don_vi_tinh), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "SL theo hóa đơn",
        width: "80",
        align: "center"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.sl), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Kết quả kiểm nghiệm",
        width: "120",
        align: "center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            label: "Đạt ",
            width: "120",
            align: "center"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.dat), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "Không đạt",
            width: "120",
            align: "center"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.khong_dat), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Phương thức kiểm nghiệm",
        width: "110",
        align: "center"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.phuong_thuc), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}