import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    style: {"width":"100%"},
    "empty-text": "Chưa có thành viên",
    size: "small",
    "cell-style": {padding: '0', height: '15px'},
    data: _ctx.data
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        label: "STT",
        width: "55"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.stt), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "Họ và tên" }),
      _createVNode(_component_el_table_column, { label: "Chức vụ chuyên môn" }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.ma_vat_tu), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "Thành phần trong hội đồng" }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.don_vi_tinh), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}